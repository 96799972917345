<template>
  <div>
    <div id="selectPanel">
      <h3 id="pageTitleContainer">
        <span id="pageNumber">02</span>
        <span id="pageTitle">Select Plan</span>
      </h3>

      <h4 id="introText">Which of the following best describes your commission pay plan?</h4>

      <div id="planSelectorsContainer">
        <div>
          <v-row class="match-height">
            <v-col
              md="4"
              sm="6"
              cols="12"
            >
              <v-card
                elevation
              >
                <v-card-title>
                  Single Pay Plan
                </v-card-title>
                <div class="kb-character-wrapper">
                  <v-img
                    contain
                    class="mb-5"
                    height="250"
                    src="/monthlyReviewImages/single_pay_plan.png"
                  ></v-img>
                </div>

                <!-- title -->

                <v-card-text>
                  Pay everyone the same. Create a single set of rates for new business and renewals
                  that will apply to all producers.
                </v-card-text>

                <v-card-actions>
                  <v-btn color="primary" plain @click.prevent="selectType('single')">
                    Select Plan
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              md="4"
              sm="6"
              cols="12"
            >
              <v-card
                elevation
              >
                <v-card-title>
                  Multiple Pay Plans
                </v-card-title>
                <div class="kb-character-wrapper">
                  <v-img
                    contain
                    class="mb-5"
                    height="250"
                    src="/monthlyReviewImages/multiple_pay_plans.png"
                  ></v-img>
                </div>

                <!-- title -->

                <v-card-text>
                  Pay different rates to groups or individuals. You can specify new business and
                  renewal rates for each plan.
                </v-card-text>

                <v-card-actions>
                  <v-btn color="primary" plain @click.prevent="selectType('multiple')">
                    Select Plan
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              md="4"
              sm="6"
              cols="12"
            >
              <v-card
                elevation
              >
                <v-card-title>
                  I'll Calculate It Myself
                </v-card-title>
                <div class="kb-character-wrapper">
                  <v-img
                    contain
                    class="mb-5"
                    height="250"
                    src="/monthlyReviewImages/no_plans.png"
                  ></v-img>
                </div>

                <!-- title -->

                <v-card-text>
                  Export your report and calculate the commission on your own.
                </v-card-text>

                <v-card-actions>
                  <v-btn color="primary" plain @click.prevent="selectType('none')">
                    Select Plan
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="text-right mt-8">
        <v-btn plain color="info" @click.prevent="$emit('goToStep',1)">
          <v-icon>
            arrow_back
          </v-icon>
          Back
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    selectType(type) {
      if (type === 'none') {
        this.$api.commissionSetup.savePlanType({
          type: 'none',
        }).then(() => {
          this.$emit('goToStep', 4);
        })
      } else {
        this.$emit('goToStep',3);

        this.$router.push({
          name: 'commission-settings.pay-structure',
          query: {
            type,
          },
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#selectPanel {
  background: #FFFFFF;
  padding: 16px 32px;
  margin-bottom: 8px;
}

#introText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #3A3541;
}

#planSelectorsContainer {
  margin-top: 32px;
}
</style>
